const axios = require('axios');
import {config} from '@/assets/js/Utils.js';

export function getApplePayValidation(info) {
    let path = `/applepay/get/merchant/validation`

    let postdata = new URLSearchParams();
    postdata.append("validationUrl", info.validationUrl);
    postdata.append("domainName", info.domainName);
    postdata.append("displayName", info.displayName);
    postdata.append("merchantId", info.merchantId);
    postdata.append("useNewCert", info.useNewCert);

    return axios.post(config.WS_URL + path, postdata)
}