const paymentMethods = [
    {
        id: 1,
        name: "paymentMethodCreditCard",
        icon: "icon-phone-card",
        description: "",
        componentName: null,
        nextRoute: "/payment/share",
        sorting: 90,
        config: null,
        compatibleDevices: [
            "android",
            "ios",
            "other"
        ]
    },
    {
        id: 2,
        name: "paymentMethodTerminal",
        icon: "icon-terminal",
        description: "",
        componentName: null,
        nextRoute: "/scan/terminal",
        sorting: 80,
        config: null,
        compatibleDevices: [
            "android",
            "ios",
            "other"
        ]
    },
    {
        id: 3,
        name: "paymentMethodApplePay",
        icon: "icon-applePay",
        description: "",
        componentName: "ApplePay",
        nextRoute: "/ticket",
        sorting: 10,
        config: {
            version: 4,
            merchantId: "merchant.com.worldline.dsp.wldd.demo",
            domainName: "wl-dsp-augmented-seller.pubqlf.co.as8677.net",
            displayName: "Augmented Seller",
            merchantCapabilities: [ "supports3DS" ],
            supportedNetworks: [ "visa", "masterCard", "amex", "discover", "cartesBancaires", "electron", "vPay", "maestro" ],
        },
        compatibleDevices: [
            "ios"
        ]
    },
    {
        id: 4,
        name: "paymentMethodGooglePay",
        icon: "icon-googlePay",
        description: "",
        componentName: "GooglePay",
        nextRoute: "/ticket",
        sorting: 20,
        config: {
            version: 2,
            minVersion: 0,
            supportedNetworks: ['MASTERCARD', 'VISA'],
            allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
            gatewayConfig: {
                gateway: "stripe",
                "stripe:version": "2018-11-08",
                "stripe:publishableKey": "pk_test_19ow8cjRvtMRxKzIVyTwOEam"
            },
            merchantInfo:{
                merchantId: '0123456789',
                merchantName: 'App Name'
            }
        },
        compatibleDevices: [
            "android",
            "other"
        ]
    },
    {
        id: 5,
        name: "paymentMethodSips",
        icon: "icon-phone-card",
        description: "",
        componentName: null,
        nextRoute: {name: 'PayPage', query: {customerPayment: true}},
        sorting: 90,
        config: null,
        compatibleDevices: [
            "android",
            "ios",
            "other"
        ]
    },
    {
        id: 6,
        name:  "paymentMethodToM",
        icon: "icon-phone-card",
        description: "",
        componentName: null,
        nextRoute: "/ticket",
        deepLinkUrl: (amount, returnUrl) => { 
            const request = JSON.stringify({
                currency: "EUR",
                requestedAmount: amount
            });
            var formatedParams = formatURI(`WPI_SERVICE_TYPE=WPI_SVC_PAYMENT&WPI_REQUEST=${request}&WPI_SERVICE_VERSION=2.1&WPI_SERVICE_SENDER=${returnUrl}&key=12FB4ACF031`)
            return `wltaponmobile://wpi.worldline?${formatedParams}`
        },
        returnUrl: window.location.origin + "/payment/methods",
        sorting: 90,
        config: null,
        compatibleDevices: [
            "android","other"
        ]
    }
]

export function getPaymentMethodsByDevice(deviceType){
    return paymentMethods.filter(paymentMethod => paymentMethod.compatibleDevices.includes(deviceType.toLowerCase())).sort((p1, p2) => p2.sorting - p1.sorting)
}
export function getAllPaymentMethods(){
    return paymentMethods
}
export function getPaymentMethodByName(name){
    return paymentMethods.find(paymentMethod => paymentMethod.name == name)
}
export function formatURI(params){
    return encodeURIComponent(params).replace(/%26/g, '&').replace(/%3D/g, '=').replace(/%3A/g, ':').replace(/%2C/g, ',').replace(/%2F/g, '/').replace(/%7B/g, '{').replace(/%7D/g, '}').replace(/%22/g, '"');
}