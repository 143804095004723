<template>
    <div class="pa-4 my-2 primary--text d-flex align-center rounded-pill payment-method-wrapper" @click="handleClick()">
        <div class="payment-method-icon-wrapper">
            <v-img width="56" height="56" :src="require(`@/assets/img/${paymentMethod.icon}.png`)" contain></v-img>
            <!-- <div v-if="addColorOverlay" class="color-overlay"></div> -->
        </div>
        <span class="ml-1 font-weight-bold text-caption text-uppercase">{{$t(paymentMethod.name)}}</span>
        <v-radio class="ml-auto custom-radio-btn" color="primary" on-icon="mdi-checkbox-blank-circle" off-icon="mdi-checkbox-blank-circle-outline" :value="paymentMethod.id"></v-radio>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    export default {
        name: "PaymentMethodListBloc",
        props: {
            paymentMethod: {
                type: Object
            }
        },
        computed: {
            ...mapGetters('customization', ["theme", "lang"])
        },
        methods: {
            handleClick(){
                this.$emit("paymentMethodChanged", this.paymentMethod.id)
            }
        }
    }
</script>

<style scoped>
    .payment-method-wrapper {
        border: solid 1px var(--color-primary);
    }
    .payment-method-icon-wrapper{
        position: relative;

    }

    .payment-method-icon-wrapper .color-overlay{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: var(--color-primary);
        mix-blend-mode: color;
    }

</style>