<template>
    <div class="apple-pay-wrapper">
        <div v-if="debug" class="console">
            <span class="errors" v-for="(txt, id) in con" :key="id">{{txt}}<br></span>
        </div> 
        <apple-pay-button v-if="canPay == true" buttonstyle="black" type="buy" locale="fr-FR" @click="onApplePayButtonClick(amount)"></apple-pay-button>
        <span v-else-if="canPay == false">{{$t("applePayNotSupported")}}</span>
    </div>
</template>
<script>
    import {getApplePayValidation} from "@/services/ApplePayService.js"
    
    export default {
        name: 'ApplePay',
        props: {
            paymentConfig: {
                type: Object,
                required: true
            },
            nextRoute: {
                type: String,
                required: true
            },
            amount:{
                type: Number
            }
        },
        data() {
            return {
                canPay: null,
                con: [],
                debug: false
            }
        },
        async created() {
            if (window.ApplePaySession) {
                console.log("ApplePay session found")
                ApplePaySession.canMakePaymentsWithActiveCard(this.paymentConfig.merchantId).then((canMakePayments) => {
                        if (canMakePayments) {
                            console.log("Can make payment")
                            this.canPay = true
                        } else {
                            this.canPay = false
                            console.log("Can't make payment")
                        }
                    })
                    .catch(e => {
                        console.log(e)
                    });
            } else {
                this.canPay = false
                console.log("ApplePay session not found")
            }
        },
        methods: {
            async onApplePayButtonClick(amount) {
                if (!ApplePaySession) {
                    this.con.push("No apple pay session")
                    return;
                }

                const request = {
                    countryCode: "FR",
                    currencyCode: "EUR",
                    merchantCapabilities: this.paymentConfig.merchantCapabilities,
                    supportedNetworks: this.paymentConfig.supportedNetworks,
                    total: {
                        label: this.paymentConfig.displayName,
                        amount: amount
                    }
                };

                const session = new ApplePaySession(this.paymentConfig.version, request);

                session.begin();

                session.onvalidatemerchant = async event => {
                    this.con.push("On validate merchant")
                    let info = {
                        validationUrl: event.validationURL, 
                        domainName: this.paymentConfig.domainName,
                        displayName: this.paymentConfig.displayName,
                        merchantId: this.paymentConfig.merchantId,
                        useNewCert: true
                    }
                    try{
                        let { data } = await getApplePayValidation(info)
                        session.completeMerchantValidation(data);
                    }catch(e){
                        console.error(e)
                    }finally{
                        this.con.push(JSON.stringify(response))
                        this.con.push("Merchant validation done")
                    }

                };

                session.onpaymentmethodselected = event => {
                    console.log("SelectedPayment method:", event.paymentMethod)
                    const applePayPaymentMethodUpdate = {
                        newLineItems: [],
                        newTotal: {
                            label: this.paymentConfig.displayName,
                            amount: amount
                        }
                    }
                    session.completePaymentMethodSelection(applePayPaymentMethodUpdate);
                };

                session.onshippingmethodselected = event => {
                    const update = {};
                    session.completeShippingMethodSelection(update);
                };

                session.onshippingcontactselected = event => {
                    const update = {};
                    session.completeShippingContactSelection(update);
                };

                session.onpaymentauthorized = event => {
                    const result = {
                        status: ApplePaySession.STATUS_SUCCESS
                    };
                    session.completePayment(result);
                    this.$router.push(this.nextRoute)
                };

                session.oncancel = event => {
                    console.log("ApplePay session canceled")
                    console.log(event)
                };
            }
        }
    };
</script>
<style scoped>
    .console {
        width: 100%;
        height: 200px;
        overflow: scroll;
    }

    apple-pay-button {
        --apple-pay-button-width: 140px;
        --apple-pay-button-height: 30px;
        --apple-pay-button-border-radius: 5px;
        --apple-pay-button-padding: 5px 0px;
    }

    .apple-pay {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

</style>