var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"google-play"},[_c('google-pay-button',{attrs:{"environment":"TEST","button-type":_vm.buttonType,"button-color":_vm.buttonColor,"existing-payment-method-required":_vm.existingPaymentMethodRequired},domProps:{"paymentRequest":{
          apiVersion: _vm.paymentRequest.apiVersion,
          apiVersionMinor: _vm.paymentRequest.apiVersionMinor,
          allowedPaymentMethods: _vm.paymentRequest.allowedPaymentMethods,
          merchantInfo: _vm.paymentRequest.merchantInfo,
          transactionInfo: {
            totalPriceStatus: 'FINAL',
            totalPriceLabel: 'Total',
            totalPrice: _vm.amount,
            currencyCode: 'EUR',
            countryCode: 'FR'
          },
        },"errorCallback":_vm.onError,"cancelCallback":_vm.onCancel,"clickCallback":_vm.onClick,"onPaymentAuthorizedCallback":_vm.onPaymentDataAuthorized},on:{"readytopaychange":_vm.onReadyToPayChange,"loadpaymentdata":_vm.onLoadPaymentData}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }